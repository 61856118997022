/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/prism-solarizedlight.css'
import './src/styles/prism-highlight-code.css'

/* require("prismjs/themes/prism-solarizedlight.css") */
require("prismjs/plugins/line-numbers/prism-line-numbers.css")
/**
 * Web fonts using typeface packages
 */
require("typeface-raleway")
require('typeface-source-code-pro')
require('typeface-open-sans')
require('typeface-open-sans-condensed')

